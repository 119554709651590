import React, { useCallback, useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import './App.scss';

import titleImage from './assets/images/title_small.jpg'
// import img0 from './assets/images/2011/bevern/Remember_102.jpg'
// 1990
import img1 from './assets/images/1990/1990.jpg'
import img2 from './assets/images/1990/1994.jpg'
import img3 from './assets/images/1990/1998.jpg'
// 2001
import img4 from './assets/images/2001/2001.jpg'
import img4_0 from './assets/images/2004/2004.jpg'
// 2005
import img4_1 from './assets/images/2005/2005_1.jpg'
import img4_2 from './assets/images/2005/2005_2.jpg'
import img4_3 from './assets/images/2005/2005_3.jpg'
import img4_4 from './assets/images/2005/2005_4.jpg'
// 2007
import img5 from './assets/images/2007/2007.jpg'
// 2008
import img6 from './assets/images/2008/106_0640.JPG'
import img6_1 from './assets/images/2008/104_0495.JPG'
import img7 from './assets/images/2008/106_0642.JPG'
import img8 from './assets/images/2008/106_0644.JPG'
import img9 from './assets/images/2008/IMG_3701.jpg'
import img10 from './assets/images/2008/IMG_3767.JPG'
// 2009
import img11 from './assets/images/2009/kompanieball-2009_019.jpg'
import img12 from './assets/images/2009/kompanieball-2009_098.jpg'
// 2010
import img13 from './assets/images/2010/01.jpg'
import img14 from './assets/images/2010/02.jpg'
import img15 from './assets/images/2010/03.jpg'
import img16 from './assets/images/2010/04.jpg'
import img18 from './assets/images/2010/06.jpg'
import img19 from './assets/images/2010/07.jpg'
import img20 from './assets/images/2010/08.jpg'
import img21 from './assets/images/2010/09.jpg'
import img22 from './assets/images/2010/10.jpg'
import img23 from './assets/images/2010/11.jpg'
import img24 from './assets/images/2010/12.jpg'
// 2011
import img25 from './assets/images/2011/bevern/Remember_001.JPG'
import img26 from './assets/images/2011/bevern/Remember_050.jpg'
import img27 from './assets/images/2011/bevern/Remember_052.jpg'
import img28 from './assets/images/2011/bevern/Remember_062.jpg'
import img29 from './assets/images/2011/bevern/Remember_078.jpg'
import img30 from './assets/images/2011/bevern/Remember_087.jpg'
import img31 from './assets/images/2011/bevern/Remember_120.jpg'
import img32 from './assets/images/2011/bevern/Remember_128.jpg'
import img33 from './assets/images/2011/bevern/Remember_159.jpg'
import img34 from './assets/images/2011/bevern/IMG_6164.JPG'
import img35 from './assets/images/2011/bevern/IMG_6222.JPG'
import img36 from './assets/images/2011/bevern/IMG_6256_ed.jpg'
import img37 from './assets/images/2011/bevern/IMG_6357.JPG'
import img38 from './assets/images/2011/bevern/IMG_6461.JPG'
import img39 from './assets/images/2011/bevern/band1.jpg'
import img40 from './assets/images/2011/bevern/IMG_6185.JPG'

import badum from "./assets/sounds/badum.mp3";
import guitar from "./assets/sounds/guitar.mp3";
import woohoo from "./assets/sounds/woohoo.mp3";
import kiss from "./assets/sounds/kiss.mp3";

function App() {
  const [photoIndex, setPhotoIndex] = useState(-1);

  const images = useMemo(() => {
    return [img1, img2, img3, img4, img4_0, img4_1, img4_2, img4_3, img4_4, img5, img6, img6_1, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img40, img39]
  }
    , [])

  const nineties = [img1, img2, img3]
  const zeroes = [img4, img4_0, img4_1, img4_2, img4_3, img4_4, img5, img6, img6_1, img7, img8, img9, img10]
  const tens = [img13, img14, img15, img16, img18, img19, img21, img22, img23, img24]
  const bevern11 = [img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img40, img39]

  const handleImageClick = useCallback((img) => {
    const imgIndex = images.findIndex(el => el === img);
    setPhotoIndex(imgIndex ?? -1)
  }, [images])

  const photos = images.map(el => { return { src: el } })


  const handlePlayAudio = (soundName) => {
    const myAudio = document.getElementById("soundfile-" + soundName);
    myAudio.play();
  }

  return (
    <>
      <div className="title-image-container">
        <img src={titleImage} alt="Remember Livemusik" />
      </div>
      <div className="startpage">
        <h1>Remember</h1>
        <h3>Livemusik</h3>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <h4 className="year-headline">1990 - 1999</h4>
          <Masonry className="masonry">
            {nineties.map((el, idx) => {
              return <img className="masonry-img" src={el} alt="1990" onClick={e => handleImageClick(el)} key={`img_${idx}`} />
            })}
          </Masonry>
          <h4 className="year-headline">2000 - 2009</h4>
          <Masonry className="masonry">
            {/* <img className="masonry-img" src={img4} alt="2001" onClick={e => handleImageClick(img4)} />
            <img className="masonry-img" src={img5} alt="2007" onClick={e => handleImageClick(img5)} /> */}
            {zeroes.map((el, idx) => {
              return <img key={`img_${idx}`} className="masonry-img" src={el} alt="2000-2009" onClick={e => handleImageClick(el)} />
            })}
            <img className="masonry-img" src={img11} alt="2009" onClick={e => handleImageClick(img11)} />
            <img className="masonry-img" src={img12} alt="2009" onClick={e => handleImageClick(img12)} />
          </Masonry>
          <h4 className="year-headline">2010</h4>
          <Masonry className="masonry">
            {tens.map((el, idx) => {
              return <LazyLoadImage
                key={`img_${idx}`}
                alt={"2011"}
                className="masonry-img"
                src={el}
                onClick={e => handleImageClick(el)}
              />
            })}
          </Masonry>
          <h4 className="year-headline">2011</h4>
          {/* bevern */}
          <Masonry className="masonry">
            {bevern11.map((el, idx) => {
              return <LazyLoadImage
                key={`img_${idx}`}
                alt={"2011"}
                className="masonry-img"
                src={el}
                onClick={e => handleImageClick(el)}
              />
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      <Lightbox
        index={photoIndex}
        slides={photos}
        open={photoIndex >= 0}
        close={() => setPhotoIndex(-1)}
      />
      <footer>
        <div>
          <audio id={"soundfile-guitar"} preload="none" type="audio/mpeg" ><source src={guitar} /></audio>
          <audio id={"soundfile-drums"} preload="none" type="audio/mpeg" ><source src={badum} /></audio>
          <audio id={"soundfile-mic"} preload="none" type="audio/mpeg" ><source src={woohoo} /></audio>
          <audio id={"soundfile-kiss"} preload="none" type="audio/mpeg" ><source src={kiss} /></audio>
          <span className="emoji" onClick={() => handlePlayAudio("guitar")}>
            🎸
          </span>
          <span className="emoji" onClick={() => handlePlayAudio("drums")}>
            🥁
          </span>
          <span className="emoji" onClick={() => handlePlayAudio("mic")}>
            🎤
          </span>
        </div>
        <div>Made with 
          <span className="heart" onClick={() => handlePlayAudio("kiss")}>
          ❤️
        </span>
          in 2024</div>
      </footer>
    </>
  );
}

export default App;
